import React from 'react';
import Helmet from 'react-helmet';
import { Header } from '../components/Header';

const TemplateWrapper = ({ title, description, children }) => {
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title ? title : 'Home'}</title>
        <meta name="description" content={'description'} />
        <script src="https://kit.fontawesome.com/5e35f577dc.js" />

        <link rel="apple-touch-icon" sizes="180x180" href="/img/favicon.png" />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon.png"
          sizes="16x16"
        />

        <link rel="mask-icon" href="/img/favicon.svg" color="#ff4400" />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={'title'} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />
      </Helmet>
      <Header />
      <div>{children}</div>
    </div>
  );
};

export default TemplateWrapper;
